import React from 'react';

const Filter = (props) => {
    props.list.sort((a, b) => { return a.value < b.value ? -1 : 1 });
    var clsField = props.cls + ' wide field';
    return (
        <div className={clsField}>
            <label>{props.label}</label>
            <select 
                className="ui dropdown" 
                onChange={(event) => props.changeAction(props.name, event.target.value)}
                value={props.selected}>
                {props.showAll &&
                    <option value={-1} key={-1}>All</option>}
                {props.list.sort().map((e) =>
                    <option value={e.id} key={e.id}>{e.value}</option>
                )}
            </select>
        </div>
    )
}

export default Filter;