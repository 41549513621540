import React from 'react';
import axios from 'axios';
import moment from 'moment';
import List from './List';
import Filter from './Filter';
import Modal from './Modal';
import Pagination from './Pagination';

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 0, 
            list: [], 
            teams: [],
            filter: {day: -1, hall: -1, role: -1, time: -1, team: -1},
            sort: null,
            showModal: false,
            taskID: '',
            pagination: { current: 0, itemPerPage: 10},
            error: {message: 'hello', msgCls: 'ui hidden negative message'},
        };

        this.handleTab = this.handleTab.bind(this);
        this.cancelTask = this.cancelTask.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.startAcceptTask = this.startAcceptTask.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
        this.changePage = this.changePage.bind(this);
        this.hasListAlready = this.hasListAlready.bind(this);
        this.closeErrorMsg = this.closeErrorMsg.bind(this);
    }

    componentDidMount() {
        axios.get('https://api.lundaspelen.staffanstorpshk.se/team', {
            headers: {
                accept: 'application/json'
            }
        })
        .then((response) => {
            this.setState({
                teams: response.data.map((e) => ({id: parseInt(e.id), value: e.name}))
            }); 
        }).then(() => {
            this.handleTab(0);
        });
    }

    handleTab(active) {
        this.setState({activeTab: active});
        var url = 'https://api.lundaspelen.staffanstorpshk.se/task' + (active === 0 ? '/user/' + this.props.userId : active === 1 ? '/unmanned' : '');
        axios.get(url, {
            headers: {
                accept: 'application/json'
            }
        })
        .then((response) => {
            var l = response.data.map(o => 
                (active === 1 ? {
                    uuId: o.uuid,
                    day: moment(o.start).format('YYYY-MM-DD'), 
                    time: moment(o.start).format('HH:mm'), 
                    duration: o.duration + ' (min)',
                    hallId: o.location.id, 
                    hall: o.location.name,
                    roleId: o.role.id, 
                    role: o.role.name
                } : 
                active === 0 ? {
                    uuId: o.uuid,
                    day: moment(o.start).format('YYYY-MM-DD'),
                    time: moment(o.start).format('HH:mm'),
                    duration: o.duration + ' (min)',
                    hallId: o.location.id,
                    hall: o.location.name,
                    roleId: o.role.id,
                    role: o.role.name,
                    teamId: o.teamId,
                    team: this.state.teams.find(e => e.id === o.teamId).value,
                } : {
                    uuId: o.uuid,
                    day: moment(o.start).format('YYYY-MM-DD'),
                    time: moment(o.start).format('HH:mm'),
                    duration: o.duration + ' (min)',
                    hallId: o.location.id,
                    hall: o.location.name,
                    roleId: o.role.id,
                    role: o.role.name,
                    name: o.user ? o.user.firstname + ' ' +  o.user.lastname: ''
                }));
            l.sort((a, b) => {return a.day})
            var paginationObj = {...this.state.pagination};
            paginationObj['current'] = 0;
            this.setState({list: l, filter: {day: -1, hall: -1, role: -1, time: -1, team: -1}, pagination: paginationObj});
        })
        .catch((e) => {
            console.log(e);
            this.setState({error: {message: 'An error happened loading the list', msgCls: 'ui floating negative message'}});
        });
    }

    cancelTask(uuid) {
        axios.delete('https://api.lundaspelen.staffanstorpshk.se/task/assignment/' + uuid)
        .then(() => {
            this.setState({list: this.state.list.filter(e => e.uuId !== uuid)});
        })
        .catch((e) => {
            console.log(e);
            this.setState({
                error: {
                    message: 'An error happend canceling the task',
                    msgCls: 'ui floating negative message'
                }
            });
        })
    }
    
    startAcceptTask(uuid) {
        console.log('accepting ', uuid);
        if (this.state.teams.length > 0) {
            var obj = {...this.state.filter};    
            if (this.state.filter.team === -1) {
                obj['team'] = this.state.teams[0].id;
            }
            this.setState({showModal: true, taskID: uuid, filter: obj});
        } else {
            this.setState({
                error: {
                    message: 'There is no teams',
                    msgCls: 'ui floating negative message'
                }
            });
        }
    }

    setFilter(key, value) {
        var obj = {...this.state.filter};
        if (key !== 'day' && key !== 'time') {
            obj[key] = parseInt(value);
        }
        else {
            if (value === "-1") {
                obj[key] = -1;
            }
            else {
                obj[key] = value;
            }
        }
        var paginationObj = {...this.state.pagination};
        paginationObj['current'] = 0;
        this.setState({filter: obj, pagination: paginationObj});
    }

    modalHandler(accepted) {
        if (accepted) {
            axios.put('https://api.lundaspelen.staffanstorpshk.se/task/assignment/' + this.state.taskID, {
                userId: this.props.userId,
                teamId: this.state.filter.team
            })
            .then(() => {
                this.setState({list: this.state.list.filter(e => e.uuId !== this.state.taskID),
                    showModal: false, taskID: '' });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    error: {
                        message: 'Task already assigned to another user',
                        msgCls: 'ui floating negative message'
                    }
                });
            });
        }
        else {
            this.setState({showModal: false, taskID: ''});
        }
    }

    changePage(change) {
        console.log(change);
        var obj = {...this.state.pagination};
        if (change === 'first') {
            obj['current'] = 0;
        } else if (change === 'last') {
            obj['current'] = change;
        } else {
            obj['current'] = obj['current'] + parseInt(change);
        }
        console.log(change + '->' + obj['current']);
        this.setState({pagination: obj});
    }

    hasListAlready(filterList, elem) {
        var b = filterList.some(e => e.id === elem);
        return b;
    }

    closeErrorMsg() {
        this.setState({error: {message: '', msgCls: 'ui hidden negative message'}});
    }

    render() {
        var filteredList = this.state.list.filter(e => {return this.state.filter.day === -1 ? 
                true : this.state.filter.day === e.day })
            .filter(e => {return this.state.filter.hall === -1 ? 
                true : this.state.filter.hall === e.hallId })
            .filter(e => {return this.state.filter.role === -1 ? 
                true : this.state.filter.role === e.roleId })
            .filter(e => {return this.state.filter.time === -1 ? 
                true : this.state.filter.time === e.time });

        var filteredDays = [], filteredHalls = [], filteredRoles = [], filteredTimes = [];
        filteredList.forEach(l => {
            if (!this.hasListAlready(filteredDays, l.day)) {
                filteredDays.push({id: l.day, value: l.day});
            }
            if (!this.hasListAlready(filteredHalls, l.hallId)) {
                filteredHalls.push({id: l.hallId, value: l.hall});
            }
            if (!this.hasListAlready(filteredRoles, l.roleId)) {
                filteredRoles.push({id: l.roleId, value: l.role});
            }
            if (!this.hasListAlready(filteredTimes, l.time)) {
                filteredTimes.push({id: l.time, value: l.time});
            }
        });

        var days = [], halls = [], roles = [], times = [];
        this.state.list.forEach(l => {
            if (!this.hasListAlready(days, l.day)) days.push({id: l.day, value: l.day});
            if (!this.hasListAlready(halls, l.hallId)) halls.push({id: l.hallId, value: l.hall});
            if (!this.hasListAlready(roles, l.roleId)) roles.push({id: l.roleId, value: l.role});
            if (!this.hasListAlready(times, l.time)) times.push({id: l.time, value: l.time});
        });
        var lastPage = Math.floor(filteredList.length/this.state.pagination.itemPerPage);
        filteredList = filteredList.splice(this.state.pagination.current * this.state.pagination.itemPerPage, this.state.pagination.itemPerPage);
        return (
            <div className="ui form">
                <a 
                    rel="noopener noreferrer"
                    href="https://api.lundaspelen.staffanstorpshk.se/doc/lundaspelen_2019.pdf"
                    target="_blank"
                    className="link ui orange button"
                >
                    <i className="file icon"></i>
                    Manual
                </a>
                <div className="fields">
                    <Filter 
                        label="Day"
                        name="day"
                        list={this.state.filter.day !== -1 ? days : filteredDays}
                        changeAction={this.setFilter}
                        selected={this.state.filter.day}
                        showAll={true}
                        cls="four"
                    />
                    <Filter
                        label="Hall"
                        name="hall"
                        list={this.state.filter.hall !== -1 ? halls : filteredHalls}
                        changeAction={this.setFilter}
                        selected={this.state.filter.hall}
                        showAll={true}
                        cls={this.state.activeTab === 2 ? 'four' : 'six'}
                    />
                    <Filter
                        label="Role"
                        name="role"
                        list={this.state.filter.role !== -1 ? roles : filteredRoles}
                        changeAction={this.setFilter}
                        selected={this.state.filter.role}
                        showAll={true}
                        cls={this.state.activeTab === 2 ? 'four' : 'six'}
                    />
                    {this.state.activeTab === 2 &&
                        <Filter
                            label="Time"
                            name="time"
                            list={this.state.filter.time !== -1 ? times : filteredTimes}
                            changeAction={this.setFilter}
                            selected={this.state.filter.time}
                            showAll={true}
                            cls="four"
                        />
                    }
                </div>
                {lastPage > 0 &&
                <Pagination
                    first={this.state.pagination.current !== 0}
                    left={this.state.pagination.current !== 0}
                    right={this.state.pagination.current !== lastPage}
                    last={this.state.pagination.current !== lastPage}
                    ChangePageAction={this.changePage}
                    lastPage={lastPage}
                />}
                <div className="ui top attached tabular menu">
                    <div
                        className={(this.state.activeTab === 0 ? 'active ' : '') + 'item'}
                        onClick={(e) => this.handleTab(0)}
                    >
                        My duties
                    </div>
                    <div
                        className={(this.state.activeTab === 1 ? 'active ' : '') + 'item'}
                        onClick={(e) => this.handleTab(1)}
                    >
                        Availables
                    </div>
                    <div
                        className={(this.state.activeTab === 2 ? 'active ' : '') + 'item'}
                        onClick={(e) => this.handleTab(2)}
                    >
                        Overview
                    </div>
                </div>
                <div className="ui bottom attached active tab segment">
                    {this.state.activeTab === 0 &&
                        <List
                            showList={filteredList}
                            btnAction={this.cancelTask}
                            actionLabel='Cancel'
                        />
                    }
                    {this.state.activeTab === 1 &&
                        <List
                            showList={filteredList}
                            btnAction={this.startAcceptTask}
                            actionLabel='Accept'
                        />
                    }
                    {this.state.activeTab === 2 &&
                        <List showList={filteredList} />
                    }
                </div>
                {this.state.showModal &&
                    <Modal 
                        show={this.state.showModal} 
                        teams={this.state.teams} 
                        team={this.state.filter.team}
                        changeAction={this.setFilter}
                        clickAction={this.modalHandler}
                    />
                }
                <div className={this.state.error.msgCls}>
                    <i className="close icon" onClick={this.closeErrorMsg}></i>
                    {this.state.error.message}
                </div>
            </div>
        )
    }
}

export default Main;
