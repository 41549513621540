import React from 'react';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email: ''};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    render() {
        return (
            <div className="ui large form">
                <div className="eight wide field">
                    <div className="ui left icon input">
                        <input type="email" placeholder="email" value={this.state.email} onChange={this.handleChange} name="email" />
                        <i className="envelope icon"></i>
                    </div>
                </div>
                <div className="ui submit blue button" onClick={(e) => this.props.btnLogin(this.state.email)}>Login</div>
                <div className="ui submit green button" onClick={(e) => this.props.btnNeedReg(this.state.email)}>Register</div>
            </div>
        )
    }
}

export default Login;