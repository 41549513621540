import React from 'react';
import moment from 'moment';

const List = (props) => {
    var fields = props.showList.length > 0 ? Object.keys(props.showList[0]) : [];
    fields = fields.filter(e => !e.endsWith('Id'));
    props.showList.sort((e1, e2) => {
        if (moment(e1.day + ' ' + e1.time).isBefore(moment(e2.day + ' ' + e2.time))) return -1;
        if (moment(e1.day + ' ' + e1.time).isAfter(moment(e2.day + ' ' + e2.time))) return 1;
        if (e1.hall < e2.hall) return -1;
        if (e1.hall > e2.hall) return 1;
        if (e1.role < e2.role) return -1;
        if (e1.role > e2.role) return 1;
        if (e1.duration < e2.duration) return -1;
        if (e1.duration > e2.duration) return 1;
        return 0;
    })
    return (
        <table className="ui sortable celled table">
            <thead>
                <tr>
                    {fields.map((field) =>
                        <th key={field}>{field}</th>
                    )}
                    {props.actionLabel &&
                        <th></th>}
                </tr>
            </thead>
            <tbody>
                {props.showList.map((item) =>
                    <tr key={item.uuId}>
                        {fields.map((field, index) =>
                        <td data-label={field} key={index}>{item[field]}</td>
                    )}
                    {props.actionLabel &&
                        <td>
                        <div className="ui submit blue button" onClick={(e) => props.btnAction(item.uuId)}>{props.actionLabel}</div>
                        </td>}
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default List;