import React from 'react';
import axios from 'axios';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Main from './components/Main';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            name: '',
            email: null,
            needRegister: false,
            error: {message: '', msgCls: 'ui hidden negative message'},
        };

        this.login = this.login.bind(this);
        this.register = this.register.bind(this);
        this.needRegister = this.needRegister.bind(this);
        this.closeErrorMsg = this.closeErrorMsg.bind(this);
    }

    login(email) {
        console.log('calling axios ' + email);
        axios.get('https://api.lundaspelen.staffanstorpshk.se/user/' + email, {
            headers: {
                accept: 'application/json'
            }
        })
            .then((response) => {
                this.setState({ email, userId: response.data.id, name: response.data.firstname });
            })
            .catch((response) => {
                console.log(response);
                console.log('need to register!');
                this.setState({ email, needRegister: true });
            });
    }

    register(fName, lName, email, phone) {
        console.log('calling axios ' + email);
        axios.put('https://api.lundaspelen.staffanstorpshk.se/user/' + email, {
            'firstname': fName,
            'lastname': lName,
            'phone': phone
        },
            {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                this.setState({ email, needRegister: false });
                this.login(email);
            })
            .catch((response) => {
                console.log('error register!')
                if (response.response.status === 404) {
                    this.setState({
                        error: {
                            message: 'Email is mandatory', 
                            msgCls: 'ui floating negative message register'
                        }
                    });
                }
                else {
                    this.setState({
                        error: {
                            message: response.response.data.error.err.msg, 
                            msgCls: 'ui floating negative message register'
                        }
                    });
                }
            });
    }

    needRegister(email) {
        this.setState({ email, needRegister: true });
    }

    closeErrorMsg() {
        this.setState({error: {message: '', msgCls: 'ui hidden negative message'}});
    }

    render() {
        return (
            <div className="ui container">
                {this.state.needRegister && 
                    <Register btnReg={this.register} email={this.state.email} />}
                {this.state.email !== null && !this.state.needRegister && 
                    <Main email={this.state.email} userId={this.state.userId} name={this.state.name} />}
                {this.state.email == null && !this.state.needRegister && 
                    <Login btnLogin={this.login} btnNeedReg={this.needRegister} />}
                <div className={this.state.error.msgCls}>
                    <i className="close icon" onClick={this.closeErrorMsg}></i>
                    {this.state.error.message}
                </div>
            </div>)
    }
}
export default App;
