import React from 'react';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {firstName: '', lastName: '', email: this.props.email, phone: ''};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, field) {
        const obj = {};
        obj[field] = event.target.value;
        this.setState( obj );
    }

    render() {
        return (
            <div className="ui large form">
                <div className="two fields">
                    <div className="field">
                        <div className="ui left icon input">
                            <input type="text" placeholder="First name*" value={this.state.firstName} onChange={(e) => this.handleChange(e, 'firstName')} />
                            <i className="user icon"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui left icon input">
                            <input type="text" placeholder="Last name*" value={this.state.lastName} onChange={(e) => this.handleChange(e, 'lastName')} />
                            <i className="user icon"></i>
                        </div>
                    </div>
                </div>
                <div className="two fields">
                    <div className="field">
                        <div className="ui left icon input">
                            <input type="text" placeholder="email*" value={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
                            <i className="envelope icon"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui left icon input">
                            <input type="text" placeholder="phone*" value={this.state.phone} onChange={(e) => this.handleChange(e, 'phone')} />
                            <i className="phone icon"></i>
                        </div>
                    </div>
                </div>
                <div className="ui submit primary button" onClick={() => this.props.btnReg(this.state.firstName, this.state.lastName, this.state.email, this.state.phone)}>Register</div>
            </div>
        )
    }
}

export default Register;