import React from 'react';
import Filter from './Filter';

const Modal = (props) => {
    const modalClass = props.show ? 'ui active mini modal' : 'ui mini modal';
    return (
        <div className="cover">
            <div className={modalClass}>
                <Filter label="Teams" name="team" list={props.teams} selected={props.team} changeAction={props.changeAction} showAll={false} />
                <div className="buttons">
                    <div className="positive ui button" onClick={() => props.clickAction(true)}>OK</div>
                    <div className="negative ui button" onClick={() => props.clickAction(false)}>Cancel</div>
                </div>
            </div>
        </div>
    )
}

export default Modal;
