import React from 'react';

const Pagination = (props) => {
    var clsFirst = props.first ? 'ui button' : 'ui disabled button',
    clsLeft = props.left ? 'ui button' : 'ui disabled button',
    clsRight = props.right ? 'ui button' : 'ui disabled button',
    clsLast = props.last ? 'ui button' : 'ui disabled button';

    return (
        <div className="ui icon buttons">
            <button className={clsFirst} onClick={() => props.ChangePageAction('first')}>
                <i className="angle double left icon"></i>
            </button>
            <button className={clsLeft} onClick={() => props.ChangePageAction('-1')}>
                <i className="angle left icon"></i>
            </button>
            <button className={clsRight} onClick={() => props.ChangePageAction('+1')}>
                <i className="angle right icon"></i>
            </button>
            <button className={clsLast} onClick={() => props.ChangePageAction(props.lastPage)}>
                <i className="angle double right icon"></i>
            </button>
        </div>
    )
}

export default Pagination;